import { DateCalendar } from '@mui/x-date-pickers';
import './appointment-section.css';
import { Button, Col, Container, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { useState } from 'react';
import dayjs from 'dayjs';
import { EmailInput } from '../fields/email-input/email-input';
import { Textarea } from '../fields/text-area/text-area';
import { isValidEmail } from '../../utils/is-valid-email/is-valid-email';
import { networkCall } from '../../utils/network-call/network-call';
import { AxiosResponse } from 'axios';
import { DefaultErrorResponse } from '../../constant/type/api/error/default-error-response/default-error-respose.type';
import { ValidationErrorResponseData } from '../../constant/type/api/error/validation-error-response-data/validation-error-response-data.type.';

type AppointmentSectionProps = {
    id?: string;
};

export const AppointmentSection = ({ id }: AppointmentSectionProps) => {
    const [date, setDate] = useState(dayjs().add(1, 'day'));
    const [form, setForm] = useState({
        email: {
            value: '',
            error: ''
        },
        message: {
            value: '',
            error: ''
        }
    });
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [requestStatus, setRequestStatus] = useState('');

    const handleChange = (name: string, value: string) => {
        const newForm = JSON.parse(JSON.stringify(form));
        newForm[name].value = value;
        newForm[name].error = '';
        setForm(newForm);
    };

    const sendRequest = () => {
        const newForm = JSON.parse(JSON.stringify(form));

        // clear errors
        for (const key in newForm) {
            newForm[key].error = '';
        }

        let valid = true;

        for (const key in form) {
            if(!form[key as keyof typeof form].value) {
                valid = false;
                newForm[key].error = 'Mező kitöltése kötelező.';
            }
        }

        if(!valid) {
            setForm(newForm);
            return;
        }

        if(!date) {
            alert("Válasszon dátumot!");
        }

        if(!isValidEmail(form.email.value)) {
            newForm.email.error = 'Nem megfelelő formátum.';
            setForm(newForm);
            return;
        }

        setIsModalOpen(true);
        setRequestStatus('Időpontkérés küldése folyamatban...');
        networkCall('/appointments', {
            method: 'POST',
            body: {
                email: form.email.value,
                message: form.message.value,
                date: `${ date.get('year') }.${ (date.get('month') + 1).toString().padStart(2, '0') }.${ date.get('date').toString().padStart(2, '0') }.`
            }
        }).then(response => {

            if (!response) {

                setRequestStatus('Hiba a hálózati kapcsolatban.');
        
            } else {
        
                switch(response.status) {
                    case 0:
                        setRequestStatus('Hiba a hálózati kapcsolatban.');
                        break;
                    case 201:
                        setRequestStatus('A kérés sikeresen elküldve.');
                        break;
                    case 400:
                        if(response.data.code !== undefined){
                            
                            if(response.data.code === 2000) {
                                
                                setRequestStatus('Hibásan megadott beviteli mezők.');
                                const res: AxiosResponse<DefaultErrorResponse<ValidationErrorResponseData[]>> = response;

                                if(res.data.data) {
                                    const newForm = JSON.parse(JSON.stringify(form));
                                    for (const error of res.data.data) {
                                        if(error.field !== 'date') newForm[error.field].error = error.errors.join(' ');
                                        else setRequestStatus(`Időpont: ${ error.errors.join(' ') }`);
                                    }
                                    setForm(newForm);
                                }
                            }
                            
                        }
                        break;
                    default:
                        setRequestStatus('Ismeretlen hiba történt. Kérjük próbálja újra!');
                
                }
        
            }

        }).catch(err => {
            setRequestStatus('Ismeretlen hiba történt, kérjük próbálja újra!');
        });
    };

    return (
        <Container id={id} tag='section' className="appointment-section">
            <Row className='align-items-center'>

                <Col xs="12" lg="6">
                    <DateCalendar
                        value={date}
                        onChange={(newVal) => setDate(newVal)}
                        disableHighlightToday
                        minDate={dayjs().add(1, 'day')}
                    />
                </Col>

                <div style={{ height: '70px' }} className='d-block d-lg-none'></div>

                <Col xs="12" lg="6" className='form-container'>
                    
                    <h1 className='title'>
                        Egyeztessünk időpontot
                        <span className='corner'></span>
                    </h1>

                    <Row>
                        <Col xs="12" lg="8" style={{ marginBottom: '25px' }}>
                            <EmailInput
                                placeholder='Email cím'
                                error={form.email.error}
                                onChange={(val) => handleChange('email', val)}
                            />
                        </Col>

                        <Col xs="12">
                            <Row style={{ alignItems: 'flex-end' }}>
                                <Col xs="12" lg="8">
                                    <Textarea
                                        placeholder='Megjegyzés'
                                        error={form.message.error}
                                        onChange={(val) => handleChange('message', val)}
                                        rows={4}
                                    />
                                </Col>
                                <Col xs="12" lg={{ size: 3, offset: 1 }}>
                                    <button onClick={sendRequest}>Küldés</button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Modal isOpen={isModalOpen} backdrop={true}>
                        <ModalBody>
                            {requestStatus}
                        </ModalBody>
                        <ModalFooter>
                            <Button color='secondary' onClick={() => setIsModalOpen(false)}>Bezárás</Button>
                        </ModalFooter>
                    </Modal>

                </Col>
            </Row>
        </Container>
    );
};