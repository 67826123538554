import "./references-section.css";
import { Col, Container, Row } from "reactstrap";

type ReferencesSectionProps = {
    id?: string;
};

export const ReferencesSection = ({ id }: ReferencesSectionProps) => {
    return (
        <Container id={id} tag='section' className="references-section">
            <Row>

                <Col xs="12">

                    <div className="title-container">
                        <div className="corner"></div>
                        <h1 className="title">Beszéljenek a<br/>referenciák</h1>
                    </div>

                </Col>

                <Col xs="12">

                    <Row>

                        <Col xs="12" sm="3">

                            <div className="reference-pic">

                            </div>

                        </Col>

                        <div style={{ height: '30px' }} className='d-block d-sm-none'></div>

                        <Col xs="12" sm="3">

                            <div className="reference-pic">

                            </div>
                            
                        </Col>

                        <div style={{ height: '30px' }} className='d-block d-sm-none'></div>

                        <Col xs="12" sm="3">

                            <div className="reference-pic">

                            </div>
                            
                        </Col>

                        <div style={{ height: '30px' }} className='d-block d-sm-none'></div>

                        <Col xs="12" sm="3">

                            <div className="reference-pic">

                            </div>
                            
                        </Col>

                    </Row>

                </Col>

            </Row>
        </Container>
    );
};