type PanareaContent = {
    title: string;
    description: JSX.Element;
    image: string;
    iconWhenSelected: string;
    iconWhenUnSelected: string;
};

export const panareaContent: PanareaContent[] = [
    {
        title: 'Panoráma fotózás az immerzív élményekért.',
        description: <>Our cutting-edge VR technology and expert filmmakers bring your stories to life, creating <span className="bold">immersive experiences</span> that transport your audience to new dimensions.</>,
        image: 'image1.png',
        iconWhenSelected: 'selected-icon1.png',
        iconWhenUnSelected: 'unselected-icon1.png'
    },
    {
        title: 'Panoráma túra webre és applikációra.',
        description: <>Our cutting-edge VR technology and expert filmmakers bring your stories to life, creating <span className="bold">immersive experiences</span> that transport your audience to new dimensions.</>,
        image: 'image2.png',
        iconWhenSelected: 'selected-icon2.png',
        iconWhenUnSelected: 'unselected-icon2.png'
    },
    {
        title: 'Egyedi VR Túrák',
        description: <>Our cutting-edge VR technology and expert filmmakers bring your stories to life, creating <span className="bold">immersive experiences</span> that transport your audience to new dimensions.</>,
        image: 'image3.png',
        iconWhenSelected: 'selected-icon3.png',
        iconWhenUnSelected: 'unselected-icon3.png'
    }
];