import { Col, Container, Row } from 'reactstrap';
import './header.css';
import { getIconUrl } from '../../utils/image-helper/image-helper';
import { Link } from "react-scroll";
import { SectionIds } from '../../constant/enum/section-ids.enum';
import { useEffect, useState } from 'react';

export const Header = () => {
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    useEffect(() => {
        const handleDocumentClick = (e: any) => {
            if(!e.target.matches('.menu-for-xs-screen') && !e.target.matches('.hamburger-icon img')) {
                setIsMenuVisible(false);
            }
        };

        document.addEventListener('click', handleDocumentClick);

        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);

    const handleXSNavMenuClick = () => {
        setIsMenuVisible(false);
    };

    return (
        <header className='header-container'>
            <Container>
                <Row>
                    <Col xs="12">
                        <nav className='nav-for-larger-than-xs'>
                            <a href="/" className='home-link medium-font'>
                                <img src={getIconUrl('panarea-icon.png')} alt="panarea-icon.png" />PANAREA
                            </a>

                            <span
                                className='hamburger-icon d-block d-sm-none'
                                onClick={() => setIsMenuVisible(true)}
                            >
                                <img
                                    src={getIconUrl('hamburger-icon.png')}
                                    alt="hamburger-icon.png"
                                />
                            </span>

                            {/*
                                Larger than XS screen navigaion
                            */}
                            <ul className='d-none d-sm-block menu-for-larger-than-xs-screen'>
                                <li>
                                    <Link
                                        activeClass='active'
                                        to={SectionIds.PanareaSection}
                                        duration={300}
                                        offset={-110}
                                        smooth
                                        spy
                                    >
                                        Panarea
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass='active'
                                        to={SectionIds.AppointmentSection}
                                        duration={300}
                                        offset={-150}
                                        smooth
                                        spy
                                    >
                                        Időpont
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass='active'
                                        to={SectionIds.ReferencesSection}
                                        duration={300}
                                        offset={-150}
                                        smooth
                                        spy
                                    >
                                        Referenciák
                                    </Link>
                                </li>
                            </ul>
                        </nav>
                    </Col>
                </Row>
            </Container>
            {/*
                XS screen navigation
            */}
            <nav>
                <ul className={`d-block d-sm-none menu-for-xs-screen ${isMenuVisible ? 'visible' : ''}`}>
                    <li>
                        <Link
                            activeClass='active'
                            to={SectionIds.PanareaSection}
                            duration={300}
                            offset={-110}
                            smooth
                            spy
                            onClick={handleXSNavMenuClick}
                        >
                            Panarea
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass='active'
                            to={SectionIds.AppointmentSection}
                            duration={300}
                            offset={-150}
                            smooth
                            spy
                            onClick={handleXSNavMenuClick}
                        >
                            Időpont
                        </Link>
                    </li>
                    <li>
                        <Link
                            activeClass='active'
                            to={SectionIds.ReferencesSection}
                            duration={300}
                            offset={-150}
                            smooth
                            spy
                            onClick={handleXSNavMenuClick}
                        >
                            Referenciák
                        </Link>
                    </li>
                </ul>
            </nav>
        </header>
    );
};