import { Col, Container, Row } from 'reactstrap';
import './footer.css';
import { getIconUrl } from '../../utils/image-helper/image-helper';

export const Footer = () => {
    return (
        <footer className='footer-container'>
            <Container>
                <Row>

                    <Col xs="12" className='title'>
                        <img src={getIconUrl('panarea-icon.png')} alt="panarea-icon.png" />
                        Panarea
                    </Col>

                    <Col xs="12">
                        <Row className='menu-container'>
                            <Col xs="3">
                                <a href="#" onClick={(e) => e.preventDefault()}>Terms</a>
                            </Col>
                            <Col xs="6" className='copyright'>
                                Copyright &copy; 2024 Panarea
                            </Col>
                            <Col xs="3" style={{ textAlign: 'right' }}>
                                <a href="#" onClick={(e) => e.preventDefault()}>Privacy Policy</a>
                            </Col>
                        </Row>
                    </Col>

                </Row>
            </Container>
        </footer>
    );
};