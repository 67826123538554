import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

import { Header } from './components/header/header';
import { PanareaSection } from './components/panarea-section/panarea-section';
import { AppointmentSection } from './components/appointment-section/appointment-section';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ReferencesSection } from './components/references-section/references-section';
import { Footer } from './components/footer/footer';
import { SectionIds } from './constant/enum/section-ids.enum';

function App() {
  return (
    <div className='page-wrapper'>

      <Header/>

      <PanareaSection id={SectionIds.PanareaSection} />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AppointmentSection id={SectionIds.AppointmentSection}/>
      </LocalizationProvider>

      <ReferencesSection id={SectionIds.ReferencesSection}/>

      <Footer/>
  
    </div>
  );
}

export default App;
