import "./panarea-section.css";
import { useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { panareaContent } from "./data";
import { getIconUrl, getImageUrl } from "../../utils/image-helper/image-helper";

type PanareaSectionProps = {
    id?: string;
};

export const PanareaSection = ({ id }: PanareaSectionProps) => {
    const [selectedItemIndex, setSelectedItemIndex] = useState(0);
    const selectedItem = panareaContent.find((content, index) => index === selectedItemIndex);

    return (
        <Container id={id} tag='section' className="panarea-section">
            <Row>
                <Col xs="12" md="6" className="text">

                    <div className="corner"></div>
                    <h1 className="title">{selectedItem?.title}</h1>
                    <Row>
                        <Col xs="12" xl="7">
                            <p className="description">{selectedItem?.description}</p>
                            <button className="button">Schedule a shooting</button>
                        </Col>
                    </Row>
                    
                </Col>

                <div style={{ height: '70px' }} className='d-block d-md-none'></div>

                <Col xs="12" md="6" className="image">
                    <img
                        src={getImageUrl(selectedItem?.image === undefined ? '' : selectedItem.image)}
                        alt={selectedItem?.image}
                    />
                </Col>
                <Col xs="12" className="icons">
                    {
                        panareaContent.map((item, index) => {
                            return (
                                <div className="icon-container" key={index}>
                                    <img
                                        src={getIconUrl(index === selectedItemIndex ? item.iconWhenSelected : item.iconWhenUnSelected)}
                                        alt={index === selectedItemIndex ? item.iconWhenSelected : item.iconWhenUnSelected}
                                        onClick={() => setSelectedItemIndex(index)}
                                        /*onMouseEnter={() => setSelectedItemIndex(index)}*/
                                    />
                                    {selectedItemIndex === index && <div className="point-container"><span className="point"></span></div>}
                                </div>
                            );
                        })
                    }
                </Col>
            </Row>
        </Container>
    );
};